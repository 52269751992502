import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MsalProvider } from '@azure/msal-react';
import React from "react"
import Overview from "./pages/Overview";
import Shipping from "./pages/Shipping";
import NotFound from "./pages/Error";
import Login from "./pages/Login";
import LineStatus from "./pages/LineStatus";



function App({ instance }) {

  return (
    <MsalProvider instance={instance}>
      <BrowserRouter basename="/">
        <Routes>
          <Route>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route>
            <Route exact path="/overview" element={<Overview />} />
            <Route exact path="/shipping" element={<Shipping />} />
            <Route exact path="/linestatus" element={<LineStatus />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </MsalProvider>
  );
}

export default App;