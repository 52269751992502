import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react"
import OutsideClickHandler from 'react-outside-click-handler';

import { useMsal } from "@azure/msal-react";
import { clearStorage } from "../utils/storageUtils";

function Navbar() {

    const { instance } = useMsal();

    const location = useLocation();

    const navigate = useNavigate();
    const [isVisible, setVisible] = useState(true);

    const [clickTimeout, setClickTimeout] = useState(null);

    useEffect(() => {
        if (isVisible) {
            if (location.pathname === "/shipping") {
                document.getElementById("navbar").className = "flex border-0 bg-white w-screen"
                document.getElementById("mr")
            }
            else if (location.pathname === "/overview") {

                document.getElementById("navbar").className = "flex border-0 bg-black w-screen"
            }
            else if (location.pathname === "/linestatus") {

                document.getElementById("navbar").className = "flex border-0 bg-white w-screen"
            }

            document.getElementById("NavbarWrap").style.transition = "0.5s";
            document.getElementById("NavbarWrap").style.marginTop = "-80px"
            document.getElementById("NavbarWrap").className = "Open"
        }
        else {
            document.getElementById("NavbarWrap").style.transition = "0.5s";
            document.getElementById("NavbarWrap").style.marginTop = "0px"
            document.getElementById("NavbarWrap").className = "Close"
        }
    }, [isVisible])

    const handleOutsideClick = (event) => {
        // Shipping View Double Click Area
        if (event?.target?.id === "DockNo" || event?.target?.parentElement?.id === "Dock") {
            return
        }

        setVisible(!isVisible)
    }

    function handleLogOut() {
        const activeAccount = instance.getActiveAccount();
        clearStorage(activeAccount);

        instance.logoutPopup({
            postLogoutRedirectUri: "/",
            mainWindowRedirectUri: "/"
        })
    }

    return (
        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
            <div id="NavbarWrap" className="default" style={{ marginTop: "-80px" }}>
                <ul className="flex border-0 bg-rose-600 w-screen" id="navbar" >
                    <li className="mr-1">
                        <Link to="/overview" className="inline-block py-2 px-4 text-gray-400 hover:text-cyan-800 font-semibold">
                            <button>Overview</button>
                        </Link>
                    </li>

                    <li className="mr-1">
                        <Link to="/shipping" className="inline-block py-2 px-4 text-gray-400 hover:text-cyan-800 font-semibold">
                            <button>Shipping</button>
                        </Link>
                    </li>

                    <li className="mr-1">
                        <Link to="/linestatus" className="inline-block py-2 px-4 text-gray-400 hover:text-cyan-800 font-semibold">
                            <button>Line Status</button>
                        </Link>
                    </li>

                    <li className="mr-1">
                        <Link to="/login" className="inline-block py-2 px-4 text-gray-400 hover:text-cyan-800 font-semibold">
                            <button onClick={handleLogOut}>Logout</button>
                        </Link>
                    </li>


                </ul>
            </div>

        </OutsideClickHandler>





    );
}

export default Navbar